a {
   text-decoration: none;
   /* color: #1e1e23; */
   opacity: 1;
   font-family: "Space Grotesk";
   font-size: 1.2em;
   font-weight: 400;
   transition: 200ms;
}
a:hover {
   color: #fff;
   background-color: #7e7a7a;
}
ul {
   padding: 0;
   list-style-type: none;
   height: fit-content;
   margin: 0;
   box-sizing: border-box;
}
li {
   margin: 0;
   padding: 0;
}
.content {
   border: 1px solid #4162da;
   border-radius: 20px;
   width: 94%;
   height: 91%;
   background-color: #f5f6fa;
   overflow: hidden;
}
nav {
   /* background-color: #1e1e23; */
   height: fit-content;
}

#menuToggle {
   display: flex;
   /* border: 2px solid salmon; */
   flex-direction: column;
   position: relative;
   /* top: 25px; */
   /* left: 25px; */
   z-index: 1;
   -webkit-user-select: none;
   user-select: none;
}

#menuToggle input {
   display: none;
   width: 40px;
   height: 32px;
   position: absolute;
   cursor: pointer;
   opacity: 0;
   z-index: 2;
}

#menuToggle span {
   display: none;
   width: 29px;
   height: 2px;
   margin-bottom: 5px;
   position: relative;
   background: #fff;
   border-radius: 3px;
   z-index: 1;
   transform-origin: 5px 0px;
   transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
   transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
   transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
   opacity: 1;
   transform: rotate(45deg) translate(-3px, -1px);
   background: #36383f;
}
#menuToggle input:checked ~ span:nth-last-child(3) {
   opacity: 0;
   transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
   transform: rotate(-45deg) translate(0, -1px);
}

#menu {
   position: absolute;
   width: 100vw;
   display: flex;
   flex-direction: row;
   /* gap: 10px; */
   height: fit-content;
   /* padding: 10px 30px; */
   box-shadow: 0 0 10px #85888c;
   flex-direction: row;
   background-color: #f5f6fa;
}

#menu li {
   /* display: inline; */
   height: 100%;
   /* padding: 10px 0; */
   transition-delay: 2s;
}
#menu li a {
   display: inline-block;
   border: 2px solid transparent;
   padding: 8px 10px;
   /* height: 45px; */
   /* padding: 10px 0; */
   /* transition-delay: 2s; */
   border: 2px solid transparent;
}
#menu li .active {
   color: #fff;
   background-color: #7e7a7a;
}

#menuToggle input:checked ~ ul {
   transform: none;
}

@media only screen and (max-width: 600px) {
   #menuToggle {
      display: flex;
      /* border: 2px solid salmon; */
      flex-direction: column;
      position: relative;
      top: 25px;
      left: 25px;
      z-index: 1;
      -webkit-user-select: none;
      user-select: none;
   }
   #menu {
      display: block;
      position: absolute;
      width: fit-content;
      height: fit-content;
      box-shadow: 0 0 10px #85888c;
      margin: -60px 0 0 -60px;
      padding: 80px 0px 15px 34px;
      /* padding-top: 80px; */
      background-color: #f5f6fa;
      -webkit-font-smoothing: antialiased;
      transform-origin: 0% 0%;
      transform: translate(-100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
   }
   #menuToggle input {
      display: flex;
   }
   #menuToggle span {
      display: flex;
   }
   a {
      text-decoration: none;
      /* color: #1e1e23; */
      opacity: 1;
      font-family: "Space Grotesk";
      font-size: 0.8em;
      font-weight: 400;
      transition: 200ms;
   }
   #menu li {
      padding: 0;
      transition-delay: 2s;
   }
   #menu li a {
      display: inline-block;
      border: 2px solid transparent;
      /* padding: 0px 0px; */
      width: 150px;
      /* height: 45px; */
      /* padding: 10px 0; */
      /* transition-delay: 2s; */
      border: 2px solid transparent;
   }
}
